import React, { Component } from "react";
import {Link} from "react-router-dom";

class Achievement extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <>
                {/* =============== achievement area start =============== */}
                <div className="achievement-area p-80 mt-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="section-head pb-30">
                                    {/* <h5>Why TASTA</h5> */}
                                    <h2>Our Services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={"https://royaltasta.com/fetchimage?type=Packages&name=tastaphoto1.jpg"} alt="" className="img-fluid" />
                                    </div>
                                    <div>
                                    <Link to={`#`} className="blog-title">{"TASTA Photography"}
                                            </Link>
                                    </div>
                                    <div className="blog-details">
                                        <div className="blog-info">
                                            
                                           
                                            The best Photography team in India, that gives affordable Photography packages including everything at just single click.
                                            {/* <Link to={`#`} className="blog-comment"><i className="flaticon-comment" /><span>(3)</span>Comment</Link> */}
                                        </div>
                                        {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} className="blog-writer"></Link>
                         <div className="blog-btn">
                             <Link to={`${process.env.PUBLIC_URL}/blog-details`} className="btn-common-sm">Read More</Link>
                         </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={"https://royaltasta.com/fetchimage?type=Packages&name=snapcity1.jpg"} alt="" className="img-fluid" />
                                    </div>
                                    <div>
                                    <Link to={`#`} className="blog-title">{"Snapcity In Pune"}
                             </Link>
                                    </div>
                                    <div className="blog-details">
                                        <div className="blog-info">
                                        
                                            
                             Snapcity the best location for Pre-wedding and other shoots where you get 25+ sets for shoot and other services.
                                            {/* <Link to={`#`} className="blog-comment"><i className="flaticon-comment" /><span>(3)</span>Comment</Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={"https://royaltasta.com/fetchimage?type=Packages&name=tastabebu.jpeg"} alt="" className="img-fluid" />
                                    </div>
                                    <div>
                                    <Link to={`#`} className="blog-title">{"BEBU"}
                             </Link>
                                    </div>
                                    <div className="blog-details">
                                        <div className="blog-info">
                                            
                                          
                             The best customizable kid’s wear brand of Tasta that designs the attire especially for you little ones.
                                            {/* <Link to={`#`} className="blog-comment"><i className="flaticon-comment" /><span>(3)</span>Comment</Link> */}
                                        </div>
                                        {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} className="blog-writer"></Link>
                         <div className="blog-btn">
                             <Link to={`${process.env.PUBLIC_URL}/blog-details`} className="btn-common-sm">Read More</Link>
                         </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={"https://royaltasta.com/fetchimage?type=Packages&name=uk.jpg"} alt="" className="img-fluid" />
                                    </div>
                                    <div>
                                    <Link to={`#`} className="blog-title">{"LSFBE"}
                             </Link>
                                    </div>
                                    <div className="blog-details">
                                        <div className="blog-info">
                                        
                                           
                             London School of Fashion business and Entrepreneurship where students get the opportunity to learn value additional courses with at the International Level, with not only theoretical but also with Practical Knowledge.
                                            {/* <Link to={`#`} className="blog-comment"><i className="flaticon-comment" /><span>(3)</span>Comment</Link> */}
                                        </div>
                                        {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} className="blog-writer"></Link>
                         <div className="blog-btn">
                             <Link to={`${process.env.PUBLIC_URL}/blog-details`} className="btn-common-sm">Read More</Link>
                         </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* =============== achievement area end =============== */}
            </>
        );
    }
}

export default Achievement;
