import React, { Component } from "react";
import MainBanner from "./MainBanner";
import Packages from "./Packages";
import Appointments from "./Appointments";
import Achievement from "./Achievement";
import Destinations from "./Destinations";
import { getHomepage } from "../../../services/homepage"
import cookieCutter from 'cookie-cutter'
class HomePage extends Component {

  constructor(props) {
    super(props);
    let lang=cookieCutter.get('lang');
    this.state = {
      data: [],
      lang:lang,
      isMobile:false
    };
  }
  
  componentDidMount() {
    getHomepage(this.state.lang)
      .then(resp => {
        this.setState({ data: resp,isMobile:resp.isMobile });
      });
  }
  render() {
    const opts = {
      height: "590",
      width: "1240",
      playerVars: {
        autoplay: 1,
      },
    }
    return (
      <div>

        {/* Start Preloader Area */}
        <div className="preloader">
          <div className="loader loader1">
            <span style={{ '--i': 1 }} />
            <span style={{ '--i': 2 }} />
            <span style={{ '--i': 3 }} />
            <span style={{ '--i': 4 }} />
            <span style={{ '--i': 5 }} />
            <span style={{ '--i': 6 }} />
            <span style={{ '--i': 7 }} />
            <span style={{ '--i': 8 }} />
            <span style={{ '--i': 9 }} />
            <span style={{ '--i': 10 }} />
            <span style={{ '--i': 11 }} />
            <span style={{ '--i': 12 }} />
            <span style={{ '--i': 13 }} />
            <span style={{ '--i': 14 }} />
            <span style={{ '--i': 15 }} />
            <span style={{ '--i': 16 }} />
            <span style={{ '--i': 17 }} />
            <span style={{ '--i': 18 }} />
            <span style={{ '--i': 19 }} />
            <span style={{ '--i': 20 }} />
            <div className="rocket" />
          </div>
        </div>
        {/* End Preloader Area */}

        <MainBanner isMobile={this.state.isMobile} lang={this.state.lang} bannerdata1={this.state.data?.homeBanner} />
        <Appointments isMobile={this.state.isMobile} lang={this.state.lang} packageDetails={this.state.data?.packageDetails} />
        <Packages lang={this.state.lang} packageDetails={this.state.data?.packageDetails} />
        <Achievement/>
        <Destinations/>
      </div>
    );
  }
}

export default HomePage;
