import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import host_url from "../../../services/environment";
import videosrc from "../../../assets/images/bannervideo.mp4";

class MainBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const mainBannerOptions = {
      items: 2,
      loop: true,
      margin: 0,
      smartSpeed: 1000,
      dots: false,
      nav: true,
      autoplay: 2000,
      autoplayTimeout: 2000,
      autoplayHoverPause: true,
      animateIn: "fadeIn",
      animateOut: "fadeOut",
      navText: [
        "<i class='bx bx-chevron-left' ></i>",
        "<i class='bx bx-chevron-right'></i>",
      ],
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: false,
        },
        600: {
          items: 1,
          nav: false,
          dost: false,
        },
        1000: {
          items: 1,
          nav: true,
          loop: true,
        },
      },
    };
    return (
      <>
        {/* ===============  Main banner area start =============== */}
        <div className="main-banner">
          {this.props?.isMobile && (
            <video width="100%" height="500" autoPlay muted>
              <source src={videosrc} type="video/mp4" />
            </video>
          )}
          {!this.props?.isMobile && this.props?.bannerdata1 && (
            <>
              <OwlCarousel
                className="banner-slider owl-carousel"
                {...mainBannerOptions}
              >
                {this.props?.bannerdata1?.map((d, index) => (
                  <div
                    className="slider-item slider-item-1 "
                    style={{
                      backgroundImage:
                        "url(" +
                        host_url +
                        "/fetchimage?type=Banner&name=" +
                        d.IMG_SRC +
                        ") ",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      cursor: "pointer",
                    }}
                  >
                    <div className="container">
                      <div
                        className="slider-content wow fadeInLeft animated"
                        data-wow-delay="300ms"
                        data-wow-duration="1500ms"
                      >
                        {/* <img style={{height:"150px",width:"250px"}} src={host_url+"/fetchimage?type=Banner&name=6.gif"} alt="" /> */}
                        <h2>{d.TEXT} </h2>

                        <div className="banner-btn">
                          {/* <Link to={`${process.env.PUBLIC_URL}/package-details?pkg=1`} className="btn-common-sm">{this.props.lang=='M' ? "पूर्ण दिवसाचे पॅकेज": "Full Day Package"}</Link> */}
                          {/* <Link style={{marginTop:"-200px"}} to={`${process.env.PUBLIC_URL}/home`} className="btn-common-sm">Book a Appointment</Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </>
          )}
        </div>
      </>
    );
  }
}

export default MainBanner;
