import React                             from "react";
import ReactDOM                          from "react-dom";
import { BrowserRouter, Route, Switch }  from "react-router-dom";

//Image LightBox
// import SimpleReactLightbox               from 'simple-react-lightbox'

//Layout default import from components.
import defaultLayout                    from "./components/layouts/main";
import secondLayout                     from "./components/layouts/main-two";

//Import wrapping layout
import Layout                           from "./components/app";

//Import all page from components
import packageDetails                   from "./components/pages/package/PackageDetails";


//Initializations All Css
import './index.css';
import './index.scss';

//Default Warniing Error Hide
// console.log = console.warn = console.error = () => {};

/*
* Version : 0.1
* Event : Rendering all content to web.
* Actions: Define all routes and page.
* @return html
* */

class Root extends React.Component{
    render(){
        return(
            <BrowserRouter basename={"/"}>
                    <Switch>
                        <Route exact path='/' component={defaultLayout} />
                        <Route path={'/appointment'} component={packageDetails} />
                        <Route path={'/appointment/:type'} component={packageDetails} />

                        <Route path={`${process.env.PUBLIC_URL}/home-page-2`} component={secondLayout} />
                        <Layout>
                            {/* <Route path={`${process.env.PUBLIC_URL}/about-us`} component={aboutUs} />
                            <Route path={`${process.env.PUBLIC_URL}/booking`} component={booking} />
                            <Route path={`${process.env.PUBLIC_URL}/destination`} component={destinations} />
                            <Route path={`${process.env.PUBLIC_URL}/package`} component={packages} />
                            <Route path={`${process.env.PUBLIC_URL}/package-sidebar`} component={packageSidebar} />
                            <Route path={`${process.env.PUBLIC_URL}/package-standard`} component={packageStandard} />
                            <Route path={`${process.env.PUBLIC_URL}/package-details`} component={packageDetails} />
                            <Route path={`${process.env.PUBLIC_URL}/myaccount`} component={faq} />
                            <Route path={`${process.env.PUBLIC_URL}/error`} component={error} />
                            <Route path={`${process.env.PUBLIC_URL}/guide`} component={guide} />
                            <Route path={`${process.env.PUBLIC_URL}/gallary`} component={gallary} />
                            <Route path={`${process.env.PUBLIC_URL}/blog`} component={blog} />
                            <Route path={`${process.env.PUBLIC_URL}/blog-sidebar`} component={blogSidebar} />
                            <Route path={`${process.env.PUBLIC_URL}/blog-standard`} component={blogStandard} />
                            <Route path={`${process.env.PUBLIC_URL}/blog-details`} component={blogDetails} /> */}
                            {/* <Route path={`${process.env.PUBLIC_URL}/contact`} component={contact} /> */}
                        </Layout>
                    </Switch>
            </BrowserRouter>
        );
    }
}

ReactDOM.render(
    <React.StrictMode>
        {/* <SimpleReactLightbox> */}
            <Root />
        {/* </SimpleReactLightbox> */}
    </React.StrictMode>,
    document.getElementById("root")
);

