import React, { Component } from "react";
import {Link} from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import host_url from "../../../services/environment";

//Import Images
import destinations1Img from "../../../assets/images/destination/d-1.png"
import destinations4Img from "../../../assets/images/destination/d-4.png"
import destinations5Img from "../../../assets/images/destination/d-5.png"
import destinations6Img from "../../../assets/images/destination/d-6.png"
import destinations7Img from "../../../assets/images/destination/d-7.png"
import destinations8Img from "../../../assets/images/destination/d-8.png"
import destinations9Img from "../../../assets/images/destination/d-9.png"
import destinations10Img from "../../../assets/images/destination/d-10.png"
import destinations11Img from "../../../assets/images/destination/d-11.png"
import destinations2Img from "../../../assets/images/destination/d-2.png"
import destinations3Img from "../../../assets/images/destination/d-3.png"

class Destinations extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {

      const destinationsOptions = {
          stagePadding: 1,
          items: 3,
          loop: true,
          margin:20,
          smartSpeed: 1500,
          autoplay: false,
          dots: false,
          nav: true,
          navText : ["<i class='bx bx-chevron-left' ></i>","<i class='bx bx-chevron-right'></i>"],
          responsive:{
              0:{
                  items:1,
                  nav:false,
                  dots : false
              },
              600:{
                  items:2,
                  nav:false,
                  dost : false,
              },
              1000:{
                  items:3,
                  nav:true,
                  loop:true
              }
          }
      };

    return (
       <>
           {/* =============== Destinations area start =============== */}
           <div className="destinations-area pt-105">
               <div className="container">

                   <div className="row">
                       <div className="col-lg-12 col-md-12 col-sm-12">
                           <div className="section-head pb-40">
                               {/* <h5>Choose Your Package</h5> */}
                               <h2>Our International Shoot</h2>
                           </div>
                       </div>
                   </div>
                   <div className="row">
                       <div className="col-lg-3 col-md-3">
                           <div className="package-slider-wrap">
                               <img src={"https://royaltasta.com/fetchimage?type=shoot&name=london1.jpg"} alt="" className="img-fluid" />
                               <div className="pakage-overlay">
                                   <strong>London</strong>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-9 col-md-9">
                           <OwlCarousel className="row owl-carousel destinations-1"  {...destinationsOptions}>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=london2.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=london3.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=london4.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=london5.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=london1.jpg"} alt="" className="img-fluid" />
                               </div>
                           </OwlCarousel>
                       </div>
                   </div>
                   <div className="row">
                       <div className="col-lg-3 col-md-3">
                           <div className="package-slider-wrap">
                               <img src={"https://royaltasta.com/fetchimage?type=shoot&name=th.JPG"} alt="" className="img-fluid" />
                               <div className="pakage-overlay">
                                   <strong>Thailand</strong>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-9 col-md-9">
                           <OwlCarousel className="row owl-carousel destinations-1"  {...destinationsOptions}>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=th.JPG"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=th1.JPG"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=th2.JPG"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=th3.JPG"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=th4.JPG"} alt="" className="img-fluid" />
                               </div>
                           </OwlCarousel>
                       </div>
                   </div>

                   <div className="row">
                       <div className="col-lg-3 col-md-3">
                           <div className="package-slider-wrap">
                               <img src={"https://royaltasta.com/fetchimage?type=shoot&name=rusia1.jpg"} alt="" className="img-fluid" />
                               <div className="pakage-overlay">
                                   <strong>Russia</strong>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-9 col-md-9">
                           <OwlCarousel className="row owl-carousel destinations-1"  {...destinationsOptions}>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=rusia1.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=rusia2.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=rusia3.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=rusia4.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=rusia5.jpg"} alt="" className="img-fluid" />
                               </div>
                           </OwlCarousel>
                       </div>
                   </div>
                   
                   <div className="row">
                       <div className="col-lg-3 col-md-3">
                           <div className="package-slider-wrap">
                               <img src={"https://royaltasta.com/fetchimage?type=shoot&name=dubai1.jpg"} alt="" className="img-fluid" />
                               <div className="pakage-overlay">
                                   <strong>Dubai</strong>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-9 col-md-9">
                           <OwlCarousel className="row owl-carousel destinations-1"  {...destinationsOptions}>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=dubai2.jpg"} alt="" className="img-fluid" />
                               </div>
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=dubai3.JPG"} alt="" className="img-fluid" />
                               </div>
                            
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=dubai4.JPG"} alt="" className="img-fluid" />
                               </div>
                            
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=dubai5.JPG"} alt="" className="img-fluid" />
                               </div>
                            
                               <div className="package-card">
                                           <img style={{height:"430px"}} src={"https://royaltasta.com/fetchimage?type=shoot&name=dubai1.jpg"} alt="" className="img-fluid" />
                               </div>
                            
                             </OwlCarousel>
                       </div>
                   </div>
                  </div>
           </div>
           {/* =============== Destinations area end =============== */}
       </>
    );
  }
}

export default Destinations;
