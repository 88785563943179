import React, { Component } from "react";
import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import host_url from "../../../services/environment";
import cookieCutter from "cookie-cutter";
import moment from "moment";
// import NextNProgress from "nextjs-progressbar";
import Headers from "../../common/headers";
import Select from "react-select";
class PackageDetails extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("type")) {
      this.type = queryParams.get("type");
      alert(this.type)
    } else {
      this.type = window.location.href.substring(
        window.location.href.lastIndexOf("/") + 1
      );
      this.type = this.type.split('?')[0];
    }
    let userinfo = cookieCutter.get("userInfo");
    userinfo = userinfo ? JSON.parse(userinfo)?.resp[0] : {};
    this.state = {
      name: userinfo.NAME ? userinfo.NAME : "",
      email: userinfo.EMAIL_ID ? userinfo.EMAIL_ID : "",
      contrycode: "+91",
      phno: userinfo.PHONE_NO ? userinfo.PHONE_NO : "",
      requirment: "",
      time: "",
      userinfo: userinfo,
      startDate: "",
      referredby: "",
      budget: "",
      eventdate: "",
      servicetype: "",
      gender: "",
      age: "",
      profession:"",
      requirmentArr:
        this.type == "wedding"
          ? [
            {
              value: "Family combo",
              label: "Family combo (Bride groom and 4 family members)",
            },
            {
              value: "Bride",
              label: "Bride (नवरी) (Dahavari/Sahavari/Lehanga/Gown)",
            },
            {
              value: "Groom",
              label:
                "Groom (नवरदेव) (Angarkha/Sherwani/Nawabi/Suites/Bandgala)",
            },
            {
              value: "Special Events",
              label: "Special Events (Birthday/Anniversary/babyshoower)",
            },
            {
              value: "Shirt",
              label: "Shirt / Kurta / Trousers / Half jacket",
            },
            { value: "Saloon", label: "Saloon services" },
          ]
          : this.type == "child"
            ? [
              {
                value: "Wedding",
                label: "Wedding",
              },
              {
                value: "Naming Ceremony",
                label: "Naming Ceremony / Birthday",
              },
              {
                value: "Special evenets",
                label: "Special",
              },
              {
                value: "Photography",
                label: "Photography",
              },
            ]
            : this.type == "makeup"
              ? [
                {
                  value: "BASIC",
                  label: "BASIC MAKEUP",
                },
                {
                  value: "HD_Makeup",
                  label: "HD/3D Makeup",
                },
                {
                  value: "UltraHDMakeup",
                  label: "Ultra HD",
                },
                {
                  value: "AirBrushMakeup",
                  label: "Air Brush",
                },
              ]
              : [],
      budgetarr: [],
    };
    this.countrycodes = [
      {
        name: "India",
        dial_code: "+91",
        code: "IN",
      },
      {
        name: "United Kingdom",
        dial_code: "+44",
        code: "GB",
      },
      {
        name: "Afghanistan",
        dial_code: "+93",
        code: "AF",
      },
      {
        name: "Aland Islands",
        dial_code: "+358",
        code: "AX",
      },
      {
        name: "Albania",
        dial_code: "+355",
        code: "AL",
      },
      {
        name: "Algeria",
        dial_code: "+213",
        code: "DZ",
      },
      {
        name: "AmericanSamoa",
        dial_code: "+1684",
        code: "AS",
      },
      {
        name: "Andorra",
        dial_code: "+376",
        code: "AD",
      },
      {
        name: "Angola",
        dial_code: "+244",
        code: "AO",
      },
      {
        name: "Anguilla",
        dial_code: "+1264",
        code: "AI",
      },
      {
        name: "Antarctica",
        dial_code: "+672",
        code: "AQ",
      },
      {
        name: "Antigua and Barbuda",
        dial_code: "+1268",
        code: "AG",
      },
      {
        name: "Argentina",
        dial_code: "+54",
        code: "AR",
      },
      {
        name: "Armenia",
        dial_code: "+374",
        code: "AM",
      },
      {
        name: "Aruba",
        dial_code: "+297",
        code: "AW",
      },
      {
        name: "Australia",
        dial_code: "+61",
        code: "AU",
      },
      {
        name: "Austria",
        dial_code: "+43",
        code: "AT",
      },
      {
        name: "Azerbaijan",
        dial_code: "+994",
        code: "AZ",
      },
      {
        name: "Bahamas",
        dial_code: "+1242",
        code: "BS",
      },
      {
        name: "Bahrain",
        dial_code: "+973",
        code: "BH",
      },
      {
        name: "Bangladesh",
        dial_code: "+880",
        code: "BD",
      },
      {
        name: "Barbados",
        dial_code: "+1246",
        code: "BB",
      },
      {
        name: "Belarus",
        dial_code: "+375",
        code: "BY",
      },
      {
        name: "Belgium",
        dial_code: "+32",
        code: "BE",
      },
      {
        name: "Belize",
        dial_code: "+501",
        code: "BZ",
      },
      {
        name: "Benin",
        dial_code: "+229",
        code: "BJ",
      },
      {
        name: "Bermuda",
        dial_code: "+1441",
        code: "BM",
      },
      {
        name: "Bhutan",
        dial_code: "+975",
        code: "BT",
      },
      {
        name: "Bolivia, Plurinational State of",
        dial_code: "+591",
        code: "BO",
      },
      {
        name: "Bosnia and Herzegovina",
        dial_code: "+387",
        code: "BA",
      },
      {
        name: "Botswana",
        dial_code: "+267",
        code: "BW",
      },
      {
        name: "Brazil",
        dial_code: "+55",
        code: "BR",
      },
      {
        name: "British Indian Ocean Territory",
        dial_code: "+246",
        code: "IO",
      },
      {
        name: "Brunei Darussalam",
        dial_code: "+673",
        code: "BN",
      },
      {
        name: "Bulgaria",
        dial_code: "+359",
        code: "BG",
      },
      {
        name: "Burkina Faso",
        dial_code: "+226",
        code: "BF",
      },
      {
        name: "Burundi",
        dial_code: "+257",
        code: "BI",
      },
      {
        name: "Cambodia",
        dial_code: "+855",
        code: "KH",
      },
      {
        name: "Cameroon",
        dial_code: "+237",
        code: "CM",
      },
      {
        name: "Canada",
        dial_code: "+1",
        code: "CA",
      },
      {
        name: "Cape Verde",
        dial_code: "+238",
        code: "CV",
      },
      {
        name: "Cayman Islands",
        dial_code: "+ 345",
        code: "KY",
      },
      {
        name: "Central African Republic",
        dial_code: "+236",
        code: "CF",
      },
      {
        name: "Chad",
        dial_code: "+235",
        code: "TD",
      },
      {
        name: "Chile",
        dial_code: "+56",
        code: "CL",
      },
      {
        name: "China",
        dial_code: "+86",
        code: "CN",
      },
      {
        name: "Christmas Island",
        dial_code: "+61",
        code: "CX",
      },
      {
        name: "Cocos (Keeling) Islands",
        dial_code: "+61",
        code: "CC",
      },
      {
        name: "Colombia",
        dial_code: "+57",
        code: "CO",
      },
      {
        name: "Comoros",
        dial_code: "+269",
        code: "KM",
      },
      {
        name: "Congo",
        dial_code: "+242",
        code: "CG",
      },
      {
        name: "Congo, The Democratic Republic of the Congo",
        dial_code: "+243",
        code: "CD",
      },
      {
        name: "Cook Islands",
        dial_code: "+682",
        code: "CK",
      },
      {
        name: "Costa Rica",
        dial_code: "+506",
        code: "CR",
      },
      {
        name: "Cote d'Ivoire",
        dial_code: "+225",
        code: "CI",
      },
      {
        name: "Croatia",
        dial_code: "+385",
        code: "HR",
      },
      {
        name: "Cuba",
        dial_code: "+53",
        code: "CU",
      },
      {
        name: "Cyprus",
        dial_code: "+357",
        code: "CY",
      },
      {
        name: "Czech Republic",
        dial_code: "+420",
        code: "CZ",
      },
      {
        name: "Denmark",
        dial_code: "+45",
        code: "DK",
      },
      {
        name: "Djibouti",
        dial_code: "+253",
        code: "DJ",
      },
      {
        name: "Dominica",
        dial_code: "+1767",
        code: "DM",
      },
      {
        name: "Dominican Republic",
        dial_code: "+1849",
        code: "DO",
      },
      {
        name: "Ecuador",
        dial_code: "+593",
        code: "EC",
      },
      {
        name: "Egypt",
        dial_code: "+20",
        code: "EG",
      },
      {
        name: "El Salvador",
        dial_code: "+503",
        code: "SV",
      },
      {
        name: "Equatorial Guinea",
        dial_code: "+240",
        code: "GQ",
      },
      {
        name: "Eritrea",
        dial_code: "+291",
        code: "ER",
      },
      {
        name: "Estonia",
        dial_code: "+372",
        code: "EE",
      },
      {
        name: "Ethiopia",
        dial_code: "+251",
        code: "ET",
      },
      {
        name: "Falkland Islands (Malvinas)",
        dial_code: "+500",
        code: "FK",
      },
      {
        name: "Faroe Islands",
        dial_code: "+298",
        code: "FO",
      },
      {
        name: "Fiji",
        dial_code: "+679",
        code: "FJ",
      },
      {
        name: "Finland",
        dial_code: "+358",
        code: "FI",
      },
      {
        name: "France",
        dial_code: "+33",
        code: "FR",
      },
      {
        name: "French Guiana",
        dial_code: "+594",
        code: "GF",
      },
      {
        name: "French Polynesia",
        dial_code: "+689",
        code: "PF",
      },
      {
        name: "Gabon",
        dial_code: "+241",
        code: "GA",
      },
      {
        name: "Gambia",
        dial_code: "+220",
        code: "GM",
      },
      {
        name: "Georgia",
        dial_code: "+995",
        code: "GE",
      },
      {
        name: "Germany",
        dial_code: "+49",
        code: "DE",
      },
      {
        name: "Ghana",
        dial_code: "+233",
        code: "GH",
      },
      {
        name: "Gibraltar",
        dial_code: "+350",
        code: "GI",
      },
      {
        name: "Greece",
        dial_code: "+30",
        code: "GR",
      },
      {
        name: "Greenland",
        dial_code: "+299",
        code: "GL",
      },
      {
        name: "Grenada",
        dial_code: "+1473",
        code: "GD",
      },
      {
        name: "Guadeloupe",
        dial_code: "+590",
        code: "GP",
      },
      {
        name: "Guam",
        dial_code: "+1671",
        code: "GU",
      },
      {
        name: "Guatemala",
        dial_code: "+502",
        code: "GT",
      },
      {
        name: "Guernsey",
        dial_code: "+44",
        code: "GG",
      },
      {
        name: "Guinea",
        dial_code: "+224",
        code: "GN",
      },
      {
        name: "Guinea-Bissau",
        dial_code: "+245",
        code: "GW",
      },
      {
        name: "Guyana",
        dial_code: "+595",
        code: "GY",
      },
      {
        name: "Haiti",
        dial_code: "+509",
        code: "HT",
      },
      {
        name: "Holy See (Vatican City State)",
        dial_code: "+379",
        code: "VA",
      },
      {
        name: "Honduras",
        dial_code: "+504",
        code: "HN",
      },
      {
        name: "Hong Kong",
        dial_code: "+852",
        code: "HK",
      },
      {
        name: "Hungary",
        dial_code: "+36",
        code: "HU",
      },
      {
        name: "Iceland",
        dial_code: "+354",
        code: "IS",
      },
      {
        name: "Indonesia",
        dial_code: "+62",
        code: "ID",
      },
      {
        name: "Iran, Islamic Republic of Persian Gulf",
        dial_code: "+98",
        code: "IR",
      },
      {
        name: "Iraq",
        dial_code: "+964",
        code: "IQ",
      },
      {
        name: "Ireland",
        dial_code: "+353",
        code: "IE",
      },
      {
        name: "Isle of Man",
        dial_code: "+44",
        code: "IM",
      },
      {
        name: "Israel",
        dial_code: "+972",
        code: "IL",
      },
      {
        name: "Italy",
        dial_code: "+39",
        code: "IT",
      },
      {
        name: "Jamaica",
        dial_code: "+1876",
        code: "JM",
      },
      {
        name: "Japan",
        dial_code: "+81",
        code: "JP",
      },
      {
        name: "Jersey",
        dial_code: "+44",
        code: "JE",
      },
      {
        name: "Jordan",
        dial_code: "+962",
        code: "JO",
      },
      {
        name: "Kazakhstan",
        dial_code: "+77",
        code: "KZ",
      },
      {
        name: "Kenya",
        dial_code: "+254",
        code: "KE",
      },
      {
        name: "Kiribati",
        dial_code: "+686",
        code: "KI",
      },
      {
        name: "Korea, Democratic People's Republic of Korea",
        dial_code: "+850",
        code: "KP",
      },
      {
        name: "Korea, Republic of South Korea",
        dial_code: "+82",
        code: "KR",
      },
      {
        name: "Kuwait",
        dial_code: "+965",
        code: "KW",
      },
      {
        name: "Kyrgyzstan",
        dial_code: "+996",
        code: "KG",
      },
      {
        name: "Laos",
        dial_code: "+856",
        code: "LA",
      },
      {
        name: "Latvia",
        dial_code: "+371",
        code: "LV",
      },
      {
        name: "Lebanon",
        dial_code: "+961",
        code: "LB",
      },
      {
        name: "Lesotho",
        dial_code: "+266",
        code: "LS",
      },
      {
        name: "Liberia",
        dial_code: "+231",
        code: "LR",
      },
      {
        name: "Libyan Arab Jamahiriya",
        dial_code: "+218",
        code: "LY",
      },
      {
        name: "Liechtenstein",
        dial_code: "+423",
        code: "LI",
      },
      {
        name: "Lithuania",
        dial_code: "+370",
        code: "LT",
      },
      {
        name: "Luxembourg",
        dial_code: "+352",
        code: "LU",
      },
      {
        name: "Macao",
        dial_code: "+853",
        code: "MO",
      },
      {
        name: "Macedonia",
        dial_code: "+389",
        code: "MK",
      },
      {
        name: "Madagascar",
        dial_code: "+261",
        code: "MG",
      },
      {
        name: "Malawi",
        dial_code: "+265",
        code: "MW",
      },
      {
        name: "Malaysia",
        dial_code: "+60",
        code: "MY",
      },
      {
        name: "Maldives",
        dial_code: "+960",
        code: "MV",
      },
      {
        name: "Mali",
        dial_code: "+223",
        code: "ML",
      },
      {
        name: "Malta",
        dial_code: "+356",
        code: "MT",
      },
      {
        name: "Marshall Islands",
        dial_code: "+692",
        code: "MH",
      },
      {
        name: "Martinique",
        dial_code: "+596",
        code: "MQ",
      },
      {
        name: "Mauritania",
        dial_code: "+222",
        code: "MR",
      },
      {
        name: "Mauritius",
        dial_code: "+230",
        code: "MU",
      },
      {
        name: "Mayotte",
        dial_code: "+262",
        code: "YT",
      },
      {
        name: "Mexico",
        dial_code: "+52",
        code: "MX",
      },
      {
        name: "Micronesia, Federated States of Micronesia",
        dial_code: "+691",
        code: "FM",
      },
      {
        name: "Moldova",
        dial_code: "+373",
        code: "MD",
      },
      {
        name: "Monaco",
        dial_code: "+377",
        code: "MC",
      },
      {
        name: "Mongolia",
        dial_code: "+976",
        code: "MN",
      },
      {
        name: "Montenegro",
        dial_code: "+382",
        code: "ME",
      },
      {
        name: "Montserrat",
        dial_code: "+1664",
        code: "MS",
      },
      {
        name: "Morocco",
        dial_code: "+212",
        code: "MA",
      },
      {
        name: "Mozambique",
        dial_code: "+258",
        code: "MZ",
      },
      {
        name: "Myanmar",
        dial_code: "+95",
        code: "MM",
      },
      {
        name: "Namibia",
        dial_code: "+264",
        code: "NA",
      },
      {
        name: "Nauru",
        dial_code: "+674",
        code: "NR",
      },
      {
        name: "Nepal",
        dial_code: "+977",
        code: "NP",
      },
      {
        name: "Netherlands",
        dial_code: "+31",
        code: "NL",
      },
      {
        name: "Netherlands Antilles",
        dial_code: "+599",
        code: "AN",
      },
      {
        name: "New Caledonia",
        dial_code: "+687",
        code: "NC",
      },
      {
        name: "New Zealand",
        dial_code: "+64",
        code: "NZ",
      },
      {
        name: "Nicaragua",
        dial_code: "+505",
        code: "NI",
      },
      {
        name: "Niger",
        dial_code: "+227",
        code: "NE",
      },
      {
        name: "Nigeria",
        dial_code: "+234",
        code: "NG",
      },
      {
        name: "Niue",
        dial_code: "+683",
        code: "NU",
      },
      {
        name: "Norfolk Island",
        dial_code: "+672",
        code: "NF",
      },
      {
        name: "Northern Mariana Islands",
        dial_code: "+1670",
        code: "MP",
      },
      {
        name: "Norway",
        dial_code: "+47",
        code: "NO",
      },
      {
        name: "Oman",
        dial_code: "+968",
        code: "OM",
      },
      {
        name: "Pakistan",
        dial_code: "+92",
        code: "PK",
      },
      {
        name: "Palau",
        dial_code: "+680",
        code: "PW",
      },
      {
        name: "Palestinian Territory, Occupied",
        dial_code: "+970",
        code: "PS",
      },
      {
        name: "Panama",
        dial_code: "+507",
        code: "PA",
      },
      {
        name: "Papua New Guinea",
        dial_code: "+675",
        code: "PG",
      },
      {
        name: "Paraguay",
        dial_code: "+595",
        code: "PY",
      },
      {
        name: "Peru",
        dial_code: "+51",
        code: "PE",
      },
      {
        name: "Philippines",
        dial_code: "+63",
        code: "PH",
      },
      {
        name: "Pitcairn",
        dial_code: "+872",
        code: "PN",
      },
      {
        name: "Poland",
        dial_code: "+48",
        code: "PL",
      },
      {
        name: "Portugal",
        dial_code: "+351",
        code: "PT",
      },
      {
        name: "Puerto Rico",
        dial_code: "+1939",
        code: "PR",
      },
      {
        name: "Qatar",
        dial_code: "+974",
        code: "QA",
      },
      {
        name: "Romania",
        dial_code: "+40",
        code: "RO",
      },
      {
        name: "Russia",
        dial_code: "+7",
        code: "RU",
      },
      {
        name: "Rwanda",
        dial_code: "+250",
        code: "RW",
      },
      {
        name: "Reunion",
        dial_code: "+262",
        code: "RE",
      },
      {
        name: "Saint Barthelemy",
        dial_code: "+590",
        code: "BL",
      },
      {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        dial_code: "+290",
        code: "SH",
      },
      {
        name: "Saint Kitts and Nevis",
        dial_code: "+1869",
        code: "KN",
      },
      {
        name: "Saint Lucia",
        dial_code: "+1758",
        code: "LC",
      },
      {
        name: "Saint Martin",
        dial_code: "+590",
        code: "MF",
      },
      {
        name: "Saint Pierre and Miquelon",
        dial_code: "+508",
        code: "PM",
      },
      {
        name: "Saint Vincent and the Grenadines",
        dial_code: "+1784",
        code: "VC",
      },
      {
        name: "Samoa",
        dial_code: "+685",
        code: "WS",
      },
      {
        name: "San Marino",
        dial_code: "+378",
        code: "SM",
      },
      {
        name: "Sao Tome and Principe",
        dial_code: "+239",
        code: "ST",
      },
      {
        name: "Saudi Arabia",
        dial_code: "+966",
        code: "SA",
      },
      {
        name: "Senegal",
        dial_code: "+221",
        code: "SN",
      },
      {
        name: "Serbia",
        dial_code: "+381",
        code: "RS",
      },
      {
        name: "Seychelles",
        dial_code: "+248",
        code: "SC",
      },
      {
        name: "Sierra Leone",
        dial_code: "+232",
        code: "SL",
      },
      {
        name: "Singapore",
        dial_code: "+65",
        code: "SG",
      },
      {
        name: "Slovakia",
        dial_code: "+421",
        code: "SK",
      },
      {
        name: "Slovenia",
        dial_code: "+386",
        code: "SI",
      },
      {
        name: "Solomon Islands",
        dial_code: "+677",
        code: "SB",
      },
      {
        name: "Somalia",
        dial_code: "+252",
        code: "SO",
      },
      {
        name: "South Africa",
        dial_code: "+27",
        code: "ZA",
      },
      {
        name: "South Sudan",
        dial_code: "+211",
        code: "SS",
      },
      {
        name: "South Georgia and the South Sandwich Islands",
        dial_code: "+500",
        code: "GS",
      },
      {
        name: "Spain",
        dial_code: "+34",
        code: "ES",
      },
      {
        name: "Sri Lanka",
        dial_code: "+94",
        code: "LK",
      },
      {
        name: "Sudan",
        dial_code: "+249",
        code: "SD",
      },
      {
        name: "Suriname",
        dial_code: "+597",
        code: "SR",
      },
      {
        name: "Svalbard and Jan Mayen",
        dial_code: "+47",
        code: "SJ",
      },
      {
        name: "Swaziland",
        dial_code: "+268",
        code: "SZ",
      },
      {
        name: "Sweden",
        dial_code: "+46",
        code: "SE",
      },
      {
        name: "Switzerland",
        dial_code: "+41",
        code: "CH",
      },
      {
        name: "Syrian Arab Republic",
        dial_code: "+963",
        code: "SY",
      },
      {
        name: "Taiwan",
        dial_code: "+886",
        code: "TW",
      },
      {
        name: "Tajikistan",
        dial_code: "+992",
        code: "TJ",
      },
      {
        name: "Tanzania, United Republic of Tanzania",
        dial_code: "+255",
        code: "TZ",
      },
      {
        name: "Thailand",
        dial_code: "+66",
        code: "TH",
      },
      {
        name: "Timor-Leste",
        dial_code: "+670",
        code: "TL",
      },
      {
        name: "Togo",
        dial_code: "+228",
        code: "TG",
      },
      {
        name: "Tokelau",
        dial_code: "+690",
        code: "TK",
      },
      {
        name: "Tonga",
        dial_code: "+676",
        code: "TO",
      },
      {
        name: "Trinidad and Tobago",
        dial_code: "+1868",
        code: "TT",
      },
      {
        name: "Tunisia",
        dial_code: "+216",
        code: "TN",
      },
      {
        name: "Turkey",
        dial_code: "+90",
        code: "TR",
      },
      {
        name: "Turkmenistan",
        dial_code: "+993",
        code: "TM",
      },
      {
        name: "Turks and Caicos Islands",
        dial_code: "+1649",
        code: "TC",
      },
      {
        name: "Tuvalu",
        dial_code: "+688",
        code: "TV",
      },
      {
        name: "Uganda",
        dial_code: "+256",
        code: "UG",
      },
      {
        name: "Ukraine",
        dial_code: "+380",
        code: "UA",
      },
      {
        name: "United Arab Emirates",
        dial_code: "+971",
        code: "AE",
      },

      {
        name: "United States",
        dial_code: "+1",
        code: "US",
      },
      {
        name: "Uruguay",
        dial_code: "+598",
        code: "UY",
      },
      {
        name: "Uzbekistan",
        dial_code: "+998",
        code: "UZ",
      },
      {
        name: "Vanuatu",
        dial_code: "+678",
        code: "VU",
      },
      {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        dial_code: "+58",
        code: "VE",
      },
      {
        name: "Vietnam",
        dial_code: "+84",
        code: "VN",
      },
      {
        name: "Virgin Islands, British",
        dial_code: "+1284",
        code: "VG",
      },
      {
        name: "Virgin Islands, U.S.",
        dial_code: "+1340",
        code: "VI",
      },
      {
        name: "Wallis and Futuna",
        dial_code: "+681",
        code: "WF",
      },
      {
        name: "Yemen",
        dial_code: "+967",
        code: "YE",
      },
      {
        name: "Zambia",
        dial_code: "+260",
        code: "ZM",
      },
      {
        name: "Zimbabwe",
        dial_code: "+263",
        code: "ZW",
      },
    ];
  }

  handleWarnaChange = (selected, selectaction) => {
    let requirments = "";
    selected.forEach((ele) => {
      requirments =
        requirments + (requirments == "" ? "" : ",") + " " + ele.value + "";
    });
    if (requirments == " Saloon") {
      alert(
        "Sorry only saloon service not available! Please select other services with Saloon. "
      );
    } else {
      this.setState({ requirment: requirments });
    }
    console.log(requirments);
    console.log(requirments.includes("Family"));
    if (requirments.includes("Family")) {
      this.setState({
        budgetarr: [
          { value: "1.5 lakh - 2 lakhs", label: "1.5 lakh - 2 lakhs" },
          { value: "2 lakh - 2.5 lakhs", label: "2 lakh - 2.5 lakhs" },
          { value: "2.5 lakh - onwards", label: "2.5 lakh - onwards" },
        ],
      });
    } else if (requirments.includes("Bride") || requirments.includes("Groom")) {
      this.setState({
        budgetarr: [
          { value: "20,000 - 40,000", label: "20,000 - 40,000" },
          { value: "40,000 - 60,000", label: "40,000 - 60,000" },
          { value: "60,000 - onwards", label: "60,000 - onwards" },
        ],
      });
    } else if (requirments.includes("Special")) {
      this.setState({
        budgetarr: [
          { value: "15,000 - 25,000", label: "15,000 - 15,000" },
          { value: "25,000 - 35,000", label: "25,000 - 35,000" },
          { value: "35,000 - onwards", label: "35,000 - onwards" },
        ],
      });
    } else if (requirments.includes("Shirt")) {
      this.setState({
        budgetarr: [
          { value: "3,500 - 4,000", label: "3,500 - 4,000" },
          { value: "4,000 - 6,000", label: "4,000 - 6,000" },
          { value: "6,000 - onwards", label: "6,000 - onwards" },
        ],
      });
    }
    if (this.type == "makeup") {
      if (requirments.includes("AirBrushMakeup")) {
        this.setState({
          budgetarr: [
            {
              value: "1makeupset",
              label: "1 Makeup Look, HairStyle, Styling and Drapping(20,000 ₹)",
            },
            {
              value: "2makeupset",
              label: "2 Makeup Look, HairStyle, Styling and Drapping(35,000 ₹)",
            },
            {
              value: "3makeupset",
              label: "3 Makeup Look, HairStyle, Styling and Drapping(50,000 ₹)",
            },
          ],
        });
      } else if (requirments.includes("UltraHDMakeup")) {
        this.setState({
          budgetarr: [
            {
              value: "1makeupset",
              label: "1 Makeup Look, HairStyle, Styling and Drapping(18,000 ₹)",
            },
            {
              value: "2makeupset",
              label: "2 Makeup Look, HairStyle, Styling and Drapping(32,000 ₹)",
            },
            {
              value: "3makeupset",
              label: "3 Makeup Look, HairStyle, Styling and Drapping(45,000 ₹)",
            },
          ],
        });
      } else if (requirments.includes("HD_Makeup")) {
        this.setState({
          budgetarr: [
            {
              value: "1makeupset",
              label: "1 Makeup Look, HairStyle, Styling and Drapping(15,000 ₹)",
            },
            {
              value: "2makeupset",
              label: "2 Makeup Look, HairStyle, Styling and Drapping(28,000 ₹)",
            },
            {
              value: "3makeupset",
              label: "3 Makeup Look, HairStyle, Styling and Drapping(40,000 ₹)",
            },
          ],
        });
      } else if (requirments.includes("BASIC")) {
        this.setState({
          budgetarr: [
            {
              value: "1makeupset",
              label: "1 Makeup Look, HairStyle, Styling and Drapping(10,000 ₹)",
            },
            {
              value: "2makeupset",
              label: "2 Makeup Look, HairStyle, Styling and Drapping(18,000 ₹)",
            },
            {
              value: "3makeupset",
              label: "3 Makeup Look, HairStyle, Styling and Drapping(25,000 ₹)",
            },
          ],
        });
      }
    }
  };

  handlesalonchanges = (e) => {
    this.setState({
      requirmentArr: [],
    });

    if (this.type == "salon") {
      if (this.state.gender == "M") {
        if (e.target.value.includes("HAIR")) {
          this.setState({
            requirmentArr: [
              { value: "HAIRCUT", label: "HAIR CUT" },
              { value: "BEARDTRIM", label: "BEARD TRIMMING" },
              { value: "HAIRSPA", label: "HAIR SPA" },
              { value: "COLOR", label: "HAIR COLOR" },
            ],
          });
        } else if (e.target.value.includes("SKIN")) {
          this.setState({
            requirmentArr: [{ value: "CLEANUP", label: "CLEANUP" }],
          });
        }
      } else {
        if (this.state.gender == "F") {
          if (e.target.value.includes("HAIR")) {
            this.setState({
              requirmentArr: [
                { value: "straightening", label: "Straightening" },
                { value: "smoothing", label: "Smoothing" },
                { value: "keratin", label: "Keratin" },
                { value: "spa", label: "HAIR SPA" },
                { value: "touchup", label: "TOUCH UP" },
                { value: "globalcolor", label: "GLOBAL COLOR" },
              ],
            });
          } else if (e.target.value.includes("SKIN")) {
            this.setState({
              requirmentArr: [
                { value: "cleanup", label: "CLEANUP" },
                { value: "facial", label: "FACIAL" },
                { value: "waxing", label: "WAXING" },
                { value: "manicure", label: "Manicure" },
                { value: "pedicure", label: "Pedicure" },
              ],
            });
          }
        }
      }
    }
  };

  handlePayNow = (e) => {
    let valid = true;
    if (this.type == 'wedding') {
      if (this.state.requirment == "") {
        alert("Please Enter Requirement")
        valid = false;
      }
      if (this.state.budget == "") {
        alert("Please Enter Budget")
        valid = false;
      }
      else if (this.state.name == "") {
        alert("Please Enter Name")
        valid = false;
      }
      else if (this.state.email == "") {
        alert("Please Enter Email Id")
        valid = false;
      }
      else if (this.state.phno == "") {
        alert("Please Enter Phone Number")
        valid = false;
      }
    }
    if (valid) {
      insertdata(
        this.state.name,
        this.state.email,
        this.state.phno,
        this.state.requirment,
        this.state.time,
        this.state.startDate,
        this.state.referredby,
        this.state.budget,
        this.state.eventdate,
        this.state.gender,
        this.state.servicetype,
        this.state.age,
        this.type
      );
    }
  };

  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  render() {
    return (
      <>
        <Headers />
        {/* ==========  breadcrumb area start ========== */}
        {/* ==========  breadcrumb area end ========== */}
        <div className="package-details-wrapper pt-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <div className="package-d-sidebar">
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="p-sidebar-form">
                        <form>
                          <h5 className="package-d-head">
                            {this.state.lang == "M"
                              ? "बुक करा"
                              : "BOOK APPOINTMENT3"}
                          </h5>
                          <h5 id="orderErrMsg" className="errorSel"></h5>
                          <div className="row">
                            <div className="col-lg-12">
                              <input
                                maxLength="45"
                                type="text"
                                value={this.state.name}
                                placeholder={
                                  this.state.lang == "M"
                                    ? "तुमचं पुर्ण नाव"
                                    : "Your Full Name *"
                                }
                                onChange={(e) =>
                                  this.setState({ name: e.target.value })
                                }
                              />
                            </div>
                            <div className="col-lg-12">
                              <input
                                maxLength="500"
                                type="email"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                                placeholder={
                                  this.state.lang == "M"
                                    ? "तुमचा ईमेल आयडी"
                                    : "Your Email Id *"
                                }
                              />
                            </div>
                            <ToastContainer />
                            <div className="col-lg-2">
                              <select
                                onChange={(e) =>
                                  this.setState({ contrycode: e.target.value })
                                }
                              >
                                {this.countrycodes?.map((c) => (
                                  <>
                                    <option value="c.dial_code">
                                      {c.dial_code} [{c.name}]
                                    </option>
                                  </>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-6">
                              <input
                                type="number"
                                disabled={
                                  this.state.userinfo.PHONE_NO ? true : false
                                }
                                placeholder={
                                  this.state.lang == "M"
                                    ? "फोन नंबर "
                                    : "Enter Your Whats App ✆ Phone Number *"
                                }
                                value={this.state.phno}
                                onChange={(e) =>
                                  this.setState({ phno: e.target.value })
                                }
                              />
                            </div>
                            <div className="col-lg-12">
                              <div
                                className="calendar-input"
                                id="packageCalenderMainDiv"
                              >
                                Please Select Date Of Appointment *
                                <label
                                  style={{
                                    fontSize: "14px",
                                    color: "blue",
                                    fontStyle: "oblique",
                                    marginTop: "1px",
                                  }}
                                >
                                  {this.state.lang == "M"
                                    ? `त्वरा करा... ${this.state.bookingmsg > 4
                                      ? 4
                                      : this.state.bookingmsg
                                    }  बुकिंग स्लॉट बाकी आहेत !!!`
                                    : `Hurry up.Only few appointment slots are left!`}{" "}
                                </label>
                                <DatePicker
                                  minDate={new Date()}
                                  dateFormat="dd-MM-yyyy"
                                  style={{ margin: "auto !imporatant" }}
                                  selected={this.state.startDate}
                                  onChange={(date) => {
                                    let todayDate = new Date();
                                    let givenDate = new Date(date);
                                    let day=givenDate.getDay();
                                    
                                    let diffDays = todayDate.getDate() - givenDate.getDate();
                                    if (diffDays == 0 ) {
                                      alert("Sorry today's all booking full !");
                                    }else if(day==3){
                                      alert("Sorry we are closed on all Wenesday !");
                                    } else {
                                      this.setState({ startDate: date });
                                    }
                                  }}
                                  className="input-field check-in"
                                  placeholder="dd-mm-yy"
                                />
                                <i
                                  className="flaticon-calendar"
                                  id="packageCalenderIcon"
                                />
                              </div>
                            </div>
                            {(this.type != "prewedding" && this.type != "weddingphoto") && (
                              <div className="col-lg-12">
                                Book Your Timing Slot *
                                <select
                                  onChange={(e) =>
                                    this.setState({ time: e.target.value })
                                  }
                                >
                                  <option value="">Select Timming Slot</option>
                                  <option value="11-1">11 AM to 1 PM</option>
                                  <option value="1-3">1 PM to 3 PM</option>
                                  <option value="3-5">3 PM to 5 PM</option>
                                  <option value="5-7">5 PM to 7 PM</option>
                                  <option disabled value="7-8">
                                    7 PM to 8 PM(Fully booked)
                                </option>
                                </select>
                              </div>
                            )}
                            {this.type == "child" && (
                              <>
                                <div className="col-lg-12">
                                  GENDER *
                                  <select
                                    onChange={(e) =>
                                      this.setState({ gender: e.target.value })
                                    }
                                  >
                                    <option value="">NA</option>
                                    <option value="M">MALE</option>
                                    <option value="F">FEMALE</option>
                                  </select>
                                </div>
                                <div className="col-lg-12">
                                  AGE *
                                  <select
                                    onChange={(e) =>
                                      this.setState({ age: e.target.value })
                                    }
                                  >
                                    <option value="">Select AGE</option>
                                    <option value="0-1">0-1 Years</option>
                                    <option value="1">
                                      1st birthday (बारसे)
                                    </option>
                                    <option value="2-4">2-4 Years</option>
                                    <option value="4-6">4-6 Years</option>
                                    <option value="6-9">6-9 Years</option>
                                    <option value="9-12">9-12 Years</option>
                                  </select>
                                </div>
                              </>
                            )}
                            {this.type == "salon" && (
                              <>
                                <div className="col-lg-12">
                                  GENDER *
                                  <select
                                    onChange={(e) =>
                                      this.setState({ gender: e.target.value })
                                    }
                                  >
                                    <option value="">NA</option>
                                    <option value="M">MALE</option>
                                    <option value="F">FEMALE</option>
                                  </select>
                                </div>
                                <div className="col-lg-12">
                                  Service Type *
                                  <select
                                    onChange={(e) => {
                                      this.setState({
                                        servicetype: e.target.value,
                                      });
                                      this.handlesalonchanges(e);
                                    }}
                                  >
                                    <option value="">NA</option>
                                    <option value="HAIR">HAIR</option>
                                    <option value="SKIN">SKIN</option>
                                  </select>
                                </div>
                              </>
                            )}
                            {(this.type != "prewedding" && this.type != "weddingphoto") && (

                              <>
                               <div className="col-lg-12">
                                Profession 
                                <input
                                type="text"
                                placeholder={
                                  this.state.lang == "M"
                                    ? "फोन नंबर "
                                    : "Enter Your Profession"
                                }
                                value={this.state.profession}
                                onChange={(e) =>
                                  this.setState({ profession: e.target.value })
                                }
                              />
                              </div>
                              <div className="col-lg-12">
                                Requirements *
                                <Select
                                  // id="selectWarna"
                                  // instanceId="selectWarna"
                                  isMulti
                                  closeMenuOnSelect={false}
                                  hideSelectedOptions={false}
                                  // name="colors"
                                  // className="basic-multi-select"
                                  // classNamePrefix="select"
                                  options={this.state.requirmentArr}
                                  onChange={this.handleWarnaChange}
                                  placeholder="Please select your requirments."
                                />
                              </div>
                              </>

                            )}
                            &nbsp;
                            {this.state.budgetarr.length > 0 && (
                              <div className="col-lg-12">
                                Budget
                                <select
                                  onChange={(e) =>
                                    this.setState({ budget: e.target.value })
                                  }
                                >
                                  <option value="">Select Budgets</option>
                                  {this.state.budgetarr?.map((bud) => (
                                    <>
                                      <option value={bud.value}>
                                        {bud.label}
                                      </option>
                                    </>
                                  ))}
                                </select>
                              </div>
                            )}
                            {(this.state.type == "child" ||
                              this.state.requirment.includes("Family") ||
                              this.state.requirment.includes("Groom") ||
                              this.state.requirment.includes("Bride")) && (
                                <div className="col-lg-12">
                                  <div
                                    className="calendar-input"
                                    id="packageCalenderMainDiv"
                                  >
                                    Please Select Date Of Event *
                                  <label
                                      style={{
                                        fontSize: "14px",
                                        color: "blue",
                                        fontStyle: "oblique",
                                        marginTop: "1px",
                                      }}
                                    ></label>
                                    <DatePicker
                                      minDate={new Date()}
                                      dateFormat="dd-MM-yyyy"
                                      style={{ margin: "auto !imporatant" }}
                                      selected={this.state.eventdate}
                                      onChange={(date) => {
                                        this.setState({ eventdate: date });
                                      }}
                                      className="input-field check-in"
                                      placeholder="dd-mm-yy"
                                    />
                                    <i
                                      className="flaticon-calendar"
                                      id="packageCalenderIcon"
                                    />
                                  </div>
                                </div>
                              )}
                            <div
                              style={{ marginTop: "10px" }}
                              className="col-lg-12"
                            >
                              <button
                                className="btn btn-outline-primary"
                                onClick={(e) => this.handlePayNow()}
                                type="button"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function converttocomma(str) {
  console.log(str);
  if (str) {
    return "₹ " + str.toLocaleString();
  }
  return "₹ " + 0;
}
async function insertdata(
  name,
  email,
  phno,
  requirment,
  time,
  date,
  referredby,
  budget,
  eventdate,
  gender,
  servicetype,
  age,
  type
) {
  if (phno.length !== 10) {
    alert("Please enter 10 digit mobile number.");
  } else if (

    email == "" ||
    phno == "") {
    alert("Please fill all mandatory fields.");
  } else {
    if ((type !== 'prewedding' && type !== 'weddingphoto') && (requirment == "" ||
      time == "")) {
      alert("Please fill all mandatory fields..");
    } else {
      date = moment(date).format("YYYY-MM-DD");
      if (eventdate == "") {
        eventdate = "";
      } else {
        eventdate = moment(eventdate).format("YYYY-MM-DD");
      }
      const reqbody = {
        name: name,
        email: email,
        phno: phno,
        requirment: requirment,
        time: time,
        startDate: date,
        curdate: moment(new Date()).format("YYYY-MM-DD"),
        referredby: referredby,
        budget: budget,
        eventdate: eventdate,
        age: age,
        gender: gender,
        servicetype: servicetype,
        type: type,
      };
      const result = await axios.post(
        `${host_url}/api/v1/insertBookingData`,
        reqbody
      );
      if (!result) {
        alert("Server error. Are you online?");
        return;
      }
      if (result) {
        alert("Thanks your appointment booked successfully.");
        window.location.href = "https://royaltasta.com";
        return;
      }
    }
  }
}
export default PackageDetails;
