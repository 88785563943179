import React, { Component } from "react";
import { Link } from "react-router-dom";
import host_url from "../../../services/environment";
class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {/* ===============  Package  area start =============== */}
        {this.props.packageDetails && (
          <div className="package-area pt-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="section-head pb-45">
                    <h5>
                      {this.props.lang == "M"
                        ? "तुमचे पॅकेज निवडा"
                        : "TASTA's Product"}
                    </h5>
                    <h2>
                      {this.props.lang == "M"
                        ? "तुमच्या शूटसाठी तुमचे सर्वोत्तम पॅकेज निवडा"
                        : "Book Appointment1"}
                    </h2>
                  </div>
                </div>
              </div>
              {this.props.isMobile ? (
                <>
                  <div className="row">
                    <div
                      style={{ width: "50%", cursor: "pointer" }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                      onClick={() => {
                        window.open("./appointment/wedding", "_blank");
                      }}
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>CLOTHING</h4>
                          <div style={{ fontSize: "10px" }}>
                            Customization for<br></br> Bride | Groom | Family |
                            Function
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=CLOTHING.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/child", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>BEBU</h4>
                          <div style={{ fontSize: "10px" }}>
                            Clothing customization for <br></br> Kids
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=BEBU.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/makeup", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>MAKEUP</h4>
                          <div style={{ fontSize: "10px" }}>
                            Bride | Groom | Siders | Functions
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=MAKEUPM.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/salon", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>SALON</h4>
                          <div style={{ fontSize: "10px" }}>
                            Services for all.
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=SalonM.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/prewedding", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>Pre-Wedding Photgraphy</h4>
                          <div style={{ fontSize: "10px" }}>
                            Domestic | International
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=PREWEDDING.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/weddingphoto", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>Wedding Photgraphy</h4>
                          <div style={{ fontSize: "10px" }}>
                            Royal | Destination
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=WEDDING.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              
              ) : (
                <>
                  <div className="row">
                    <div
                      style={{ width: "50%", cursor: "pointer" }}
                      onClick={() => {
                        window.open("./appointment/wedding", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>CLOTHING</h4>
                          <div>
                            Customization for<br></br> Bride | Groom | Family |
                            Functions
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=CLOTHINGD.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/child", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>BEBU</h4>
                          <div>
                            Clothing customization for <br></br> Kids
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=BEBUD.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/makeup", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>MAKEUP</h4>
                          <div>
                            Bride | Groom | Siders | Functions
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=MAKEUPD.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/salon", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>SALON</h4>
                          <div>
                            Services for all.
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=SalonD.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/prewedding", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>Pre-Wedding Photgraphy</h4>
                          <div>
                            Domestic | International
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=PREWEDDINGD.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ width: "50%" }}
                      onClick={() => {
                        window.open("./appointment/weddingphoto", "_blank");
                      }}
                      className="col-lg-6 col-md-6 col-sm-6 wow fadeInUp animated"
                      data-wow-duration="1500ms"
                      data-wow-delay="0ms"
                    >
                      <div className="package-card">
                        <div className="section-head pb-25">
                          <h4>Wedding Photgraphy</h4>
                          <div>
                            Royal | Destination
                          </div>
                        </div>
                        <div className="package-thumb">
                          <Link >
                            <img
                              src={
                                host_url +
                                "/fetchimage?type=packages&name=WEDDINGPHOTOGRAPHYD.jpg"
                              }
                              alt=""
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="package-details">
                          <div className="package-info">
                            {/* <h5><span style={{ textDecoration: "line-through" }}>₹{d[Object.keys(d)[0]][0]["TOTAL_PRICE"]}</span>{"  "}{"  "}<span>₹{d[Object.keys(d)[0]][0]["PRICE"]}</span>/Per Shoot</h5> */}
                            {/* <h5><i className="flaticon-calendar" />{d[Object.keys(d)[0]][0]["TYPE"]}</h5> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {/* ===============  Package  area end =============== */}
      </>
    );
  }
}

export default Packages;
